import React from 'react';
import './Title.css';


class Title extends React.Component {

  render = () => {
    
    return (
      <div className='titleContainer'>
        Contagem descrescente para as férias escolares
      </div>
    );
  }
}

export default Title;
